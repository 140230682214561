import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../../layout";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import PostPreview from "../../components/PostPreview/PostPreview";
import EmptyMessaging from "../../components/EmptyMessaging/EmptyMessaging";


import "./ArticlesList.css";

const ArticlesList = ({ pageContext, data }) => {

  const { currentPageNum, pageCount } = pageContext;
  const postEdges = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <div className="articles-list">
        <Helmet title={config.siteTitle} />
        <SEO />
        { postEdges.length === 0 &&
          <EmptyMessaging/>
        }
        { postEdges.length > 0 &&
          <PostPreview postEdges={postEdges} heading="Articles"/>
        }
      </div>
    </Layout>
  );
}

export default ArticlesList;

/* eslint no-undef: "off" */
export const articlesQuery = graphql`
query ArticlesQuery {
  allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
    edges {
      node {
        fields {
          slug
          date
        }
        excerpt
        timeToRead
        frontmatter {
          title
          cover {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED 
                height:350
              )
            }
          }
          date
          category
        }
      }
    }
  }
}
`;
