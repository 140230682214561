import React from "react";
import "./EmptyMessaging.css";

const EmptyMessaging = () => {
    return(
        <div className="empty-messaging-container">
            <h1 >
                Sorry, nothing here at the moment. Stay tuned for more content! 
            </h1>
        </div>
    );
}

export default EmptyMessaging;